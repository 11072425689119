import UtilsComponent from '../components/utils.component'

export default class HomeService {
    swipers = []
    isMobile = window.matchMedia('(max-width: 1024px)').matches
    isEcomode = IRISCollectionCustomer.isEcomodeActive

    constructor() {
        this.initSectionEngine()
        this.initSectionEssentials()
        this.initSectionMap()
        this.initSectionEvents()
        UtilsComponent.initScrollReveal()
    }

    initSectionEngine() {
        if ($('.section-engine').length > 0) {
            import(
                '@scripts/components/search-engine.component' /* webpackChunkName: "scripts/search-engine.component" */
            ).then(({ default: SearchEngineComponent }) => {
                new SearchEngineComponent()
            })
        }
    }

    async initSectionEssentials() {
        const effect = !this.isMobile ? 'cards' : ''

        this.swipers.essentialsCards = await UtilsComponent.customerSlider('essentials-cards', {
            effect: effect,
            cardsEffect: {
                perSlideOffset: 6,
                perSlideRotate: 6,
            },
            grabCursor: true,
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                },
                650: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                1025: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        })

        if (!this.isMobile) {
            this.swipers.essentialsPosts = await UtilsComponent.customerSlider(
                'essentials-posts',
                {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true,
                    },
                },
                '#section-essentials .section__heading',
            )

            this.swipers.essentialsPosts.controller.control = this.swipers.essentialsCards
        }
    }

    async initSectionMap() {
        let config = {
            centeredSlides: true,
            breakpoints: {
                0: {
                    slidesPerView: 1.35,
                    spaceBetween: 10,
                },
                650: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                1025: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        }

        const optConfig = this.isMobile
            ? {
                  effect: 'coverflow',
                  grabCursor: true,
                  centeredSlides: true,
                  loop: true,
                  slidesPerView: 'auto',
                  coverflowEffect: {
                      rotate: 0,
                      stretch: -20,
                      modifier: 1,
                      slideShadows: false,
                  },
              }
            : {
                  effect: 'fade',
                  fadeEffect: {
                      crossFade: true,
                  },
              }

        config = Object.assign(config, optConfig)

        const swiperMap = (this.swipers.interactiveMap = await UtilsComponent.customerSlider(
            'map',
            config,
            !this.isMobile ? '.section-map__heading' : '',
        ))

        if (!this.isMobile) {
            swiperMap.on('slideChange', function () {
                $('.poi').removeClass('is-active')
                $(`#${this.slides[this.activeIndex].dataset.poi}`).addClass('is-active')
            })

            $.ajax({
                url: IRISCollectionCustomer.ajaxUrl,
                type: 'post',
                data: {
                    action: 'get_interactive_map',
                },
                success(data) {
                    $('#interactive-map').append(data)
                    // Active fist poi
                    const id = $('[data-poi]').eq(0).data('poi')
                    if (id) {
                        $(`#${id}`).addClass('is-active')
                    }

                    // Active poi on click
                    $('#interactive-map .poi').on('click', function () {
                        const poi = $(this).attr('id')
                        const index = $(`[data-poi="${poi}"]`).index()
                        if (index > -1) {
                            swiperMap.slideTo(index)
                            $('.poi').removeClass('is-active')
                            $(this).addClass('is-active')
                        }
                    })
                },
                error(error) {},
            })
        } else {
            swiperMap.slideToLoop(0, 0)
        }
    }

    async initSectionEvents() {
        if (this.isMobile) {
            this.swipers.agenda = await UtilsComponent.customerSlider('agenda', {
                breakpoints: {
                    0: {
                        slidesPerView: 1.15,
                        spaceBetween: 20,
                    },
                    650: {
                        slidesPerView: 2.15,
                        spaceBetween: 20,
                    },
                },
            })
        }
    }
}
